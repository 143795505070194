export var formData = {
  materialCodeName: '',
  companyName: '',
  repairAddress: '',
  receivingAddress: '',
  repairType: '',
  bz: ''

}

export var repairTypeOption = [
  { label: 'RMA返修', value: '1' },
  { label: 'DOA返修', value: '2' }
]
export var menudata = [{
  label: '+创建返修申请',
  action: 'draftContract',
  id: 1
}
]

export var rules = {
  companyCodeName: [
    { required: true, message: '请输入公司名称/编码', trigger: 'blur' }
  ],
  materialCodeName: [
    { required: true, message: '请输入供应商名称/编码', trigger: 'blur' }
  ],
  repairAddress: [
    { required: true, message: '请选择返修地址', trigger: 'change' }
  ],
  receivingAddress: [
    { required: true, message: '请选择收货地址', trigger: 'blur' }
  ],
  repairType: [
    { required: true, message: '请选择返修类型', trigger: 'blur' }
  ]
}

export var tabledata = []
