<template>
  <div class="mainform">
  <div class="mainHeader">创建返修申请单<span @click="back" class="back">返回></span></div>
   <div class="form">
    <el-form class="content bascform" ref="form" :rules="rules" :model="form" label-width="110px">
      <div class="col col4">
        <el-form-item label="供应商" prop="materialCodeName">
          <el-select size="small" filterable remote
            :remote-method="requestSupplierOption"
            :loading="supplierLoading"
            v-model="form.materialCodeName" placeholder="编码/名称">
            <el-option v-for="(item) in supplierOptions"
            :key="item.key"
            :label="item.value"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4" v-if="!id">
        <el-form-item label="公司名称" prop="companyCodeName">
          <el-select size="small" filterable remote
            :remote-method="requestCompanyOption"
            :loading="companyLoading"
            v-model="form.companyCodeName" placeholder="编码/名称">
            <el-option v-for="(item) in companyOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="返修地址" prop="repairAddress">
          <el-select v-model="form.repairAddress" @change="repairAddresschange"  placeholder="请选择">
            <el-option v-for="(item,index) in repairOptions" :key="index" :label="item.label" :value="item.label"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="收货地址" prop="receivingAddress">
          <el-select v-model="form.receivingAddress" @change="receivingAddresschange"  placeholder="请选择">
            <el-option v-for="(item,index) in receivingOptions" :key="index" :label="item.label" :value="item.label"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="返修类型" prop="repairType">
          <el-select v-model="form.repairType" placeholder="请选择">
            <el-option :key="index" v-for="(itme,index) in repairTypeOption" :label="itme.label" :value="itme.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="备注">
          <el-input  v-model="form.bz"  ></el-input>
        </el-form-item>
      </div>
      <div class="forrepair">
        <el-form-item label="待修部件" prop="">
          <el-button size="small" type="primary"  @click="ChooseSn">选择部件</el-button>
        </el-form-item>
        <div class="content ">
          <div class="tableList" >
            <div class="table">
              <el-table  style="width: 100%" :data="tabledata">
                  <el-table-column prop="materialCode" label="物料编号" :show-overflow-tooltip="true"> </el-table-column>
                  <el-table-column prop="materialDesc" label="物料描述" width="150px" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="materialNum" label="数量" :show-overflow-tooltip="true">
                    <template #default="scope">
                      <el-input v-model="scope.row.materialNum" @input="(val)=>{scope.row.materialNum}" placeholder="请输入"></el-input>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="" label="查看SN" >
                    <template #default="scope">
                      <el-button size="mini" type="text" @click="sNShow(scope.$index, scope.row)">SN</el-button>
                    </template>
                  </el-table-column> -->
                  <el-table-column prop="outboundBase" label="出库基地" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="plant" label="工厂" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="storageLand" label="库位" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="" label="操作" >
                    <template #default="scope">
                      <el-button size="mini" type="text" @click="deleteRow(scope.$index, tabledata)">删除</el-button>
                    </template>
                  </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
   <div class="action" >
    <el-button class="submit" type="primary" size="medium"  @click="submitBtn">提交</el-button>
   </div>
   <dialog-box  dialogWidth="826px" ref="dialoginfo" :form="form" :dialogShow="dialogShow" @handleClose='handleClose' @handleAffirm="handleAffirm" title="选择部件"
   :buttonData="buttonData" componentName="CreatRepairDialog"></dialog-box>
    <!-- <dialog-box  dialogWidth="826px"  :dialogShow="sNdialogShow" @handleClose='handleClose'  @confirmAdd="confirmAdd" title="查看SN" componentName="SNDialog"
   :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]" ></dialog-box> -->
</div>
</template>

<script>
import { requestForm, baseURL, request } from '@/assets/js/http.js'
import axios from 'axios'
import Component from '@/assets/js/components.js'
import { formData, rules, repairTypeOption, tabledata } from './js/CreatRepair.js'
export default {
  name: 'CreatRepair',
  components: Component.components,
  data: function () {
    return {
      dialogShow: false,
      sNdialogShow: false,
      repairTypeOption: repairTypeOption,
      form: formData,
      tabledata: tabledata,
      supplierOptions: [],
      companyOptions: [],
      repairOptions: [],
      receivingOptions: [], // 收货
      rules: rules,
      companyLoading: false,
      supplierLoading: false,
      id: '',
      buttonData: [
        {
          label: '取消',
          action: 'handleClose',
          size: 'mini'
        },
        {
          label: '确认',
          size: 'mini',
          action: 'handleAffirm',
          type: 'primary'
        }
      ]
    }
  },
  mounted () {
    this.receivingAddresschange()
    this.repairAddresschange()
  },
  methods: {
    // 获取供应商
    requestSupplierOption (val) {
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/supplier/basicData/getForSelect', 'post', { supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({ key: `${item.erpCode}-${item.name}`, value: `${item.erpCode}-${item.name}` })
            this.supCode = item.erpCode
          }
        }
        this.supplierLoading = false
      })
    },
    receivingAddresschange (val) { // 收货地址
      request('/api/repair/repair/queryBaseInfo', 'get').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            this.receivingOptions.push({
              label: item.baseCodeName,
              value: item.baseCodeName
            })
          })
        }
      })
    },
    repairAddresschange (val) { // 返修地址
      request('/api/repair/repair/queryBaseInfo', 'get').then((res) => {
        if (res.code === '200') {
          for (const item of res.data) {
            this.repairOptions.push({
              label: item.baseCodeName,
              value: item.baseCodeName
            })
          }
        }
      })
    },
    // 获取公司
    requestCompanyOption (val) {
      this.companyLoading = true
      this.companyOptions = []
      axios.post(`${baseURL}/api/sap/getCompanyCodeInfo`, val, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          token: localStorage.getItem('token')
        }
      }).then((Response) => {
        if (Response.data.code === 200) {
          for (const item of Response.data.data) {
            this.companyOptions.push({ key: `${item.companyCode}-${item.companyName}`, value: `${item.companyCode}-${item.companyName}` })
          }
        } else {
          this.$message({
            message: Response.data.msg,
            type: 'error'
          })
        }
        this.companyLoading = false
      })
    },
    // 选中部件
    ChooseSn () {
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      this.dialogShow = true
    },
    handleAffirm () { // 弹框确认按钮
      var newListData = this.$refs.dialoginfo.$refs.content.$refs.table.selectData
      if (newListData.length > 0) {
        this.tabledata = this.$refs.dialoginfo.$refs.content.$refs.table.selectData
        this.dialogShow = false
      } else {
        this.$message({
          message: '请选择要添加的部件',
          type: 'warning'
        })
      }
    },
    // sNShow (index, row) { // SN弹框
    //   this.sNdialogShow = true
    //   console.log('row', row)
    // },
    // 提交
    submitBtn () {
      const obj = {
        repairDto: this.form,
        repairDetailsDtos: this.tabledata
      }
      request('/api/repair/repair/saveRepair', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        }
        this.$router.push({
          path: '/InventoryDelivery/PurchaseRepair'
        })
      })
    },
    // 删除一条数据
    deleteRow (index, rows) {
      rows.splice(index, 1)
    },
    // 关闭弹窗
    handleClose () {
      this.dialogShow = false
      // this.sNdialogShow = false
    },
    // confirmAdd () {
    //   this.sNdialogShow = false
    // },
    // 返回
    back () {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elform.scss';
  @import '@/assets/css/tableList.scss';
  .forrepair{
    :deep(.tableList){
      padding: 20px 0px 0px 110px;
    }
  }
  .forrepair{
     padding: 10px;
  }
</style>
